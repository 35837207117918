import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// @ts-expect-error vite magic in action
import resources from "virtual:i18next-loader";

i18n.use(initReactI18next).init({
  fallbackLng: "en",
  debug: import.meta.env.DEV,
  defaultNS: "common",
  load: "languageOnly",
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  resources,
});

export default i18n;
